import { render, staticRenderFns } from "./fancyEditTableItem.vue?vue&type=template&id=f82ba60c&scoped=true"
import script from "./fancyEditTableItem.vue?vue&type=script&lang=js"
export * from "./fancyEditTableItem.vue?vue&type=script&lang=js"
import style0 from "./fancyEditTableItem.vue?vue&type=style&index=0&id=f82ba60c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f82ba60c",
  null
  
)

component.options.__file = "fancyEditTableItem.vue"
export default component.exports