import Vue from "vue";
import VueRouter from "vue-router";
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

// 路由懒加载
const Login = () => import("../views/Login.vue");
const Index = () => import("../views/Index.vue");
const Welcome = () => import("../views/auth/index/Welcome.vue");
const RoleMenu = () => import("../views/auth/roleMenu/RoleMenu.vue");

const employeeManagement = () =>
  import("../views/auth/employee/EmployeeManagement.vue");
const employeeDetail = () =>
  import("../views/auth/employee/EmployeeDetail.vue");
const OrganizationManagement = () =>
  import("../views/auth/organization/OrganizationManagement.vue");
const Post = () => import("../views/auth/post/Post.vue");
const Menu = () => import("../views/auth/menu/Menu.vue");
const Authority = () => import("../views/auth/authority/Authority.vue");
const OrganizationTypeMaintenance = () =>
  import("../views/auth/organizationType/OrganizationType.vue");
const myAccountView = () => import("../views/auth/my/MyAccountView.vue");
const SystemConfig = () => import("../views/systemConfig/SystemConfig.vue");
const SystemConfigDetails = () =>
  import("../views/systemConfig/SystemConfigDetails.vue");
const TableDesign = () => import("../views/coding/tableDesign/TableDesign.vue");
const Table = () => import('../views/coding/table/Table.vue')
const TableDetails = () => import('../views/coding/table/TableDetails.vue')
const TableDdl = () => import('../views/coding/tableDdl/TableDdl.vue')
const TableDdlDetails = () => import('../views/coding/tableDdl/TableDdlDetails.vue')
// const FromDesigner = () => import('../views/fromDesigner/FromDesigner.vue')


const LoginLog = () => import('../views/loginLog/LoginLog.vue')
const LoginLogDetails = () => import('../views/loginLog/LoginLogDetails.vue')

//design
const FromDesigner = () => import('../components/formDesigner.vue')
const DialogTest   = () => import('../views/fromDesigner/dialogTest.vue')
const QueryDialogTest   = () => import('../views/fromDesigner/queryDialogTest.vue')
const View   = () => import('../views/fromDesigner/view.vue')
const DesignTable   = () => import('../views/fromDesigner/table.vue')
const Area = () => import('../views/area/Area.vue')
const CallRecordsCalculate = () => import('../views/callRecordsCalculate/CallRecordsCalculate.vue')
const CallRecordsDownloadPolicy = () => import('../views/callRecordsDownloadPolicy/CallRecordsDownloadPolicy.vue')
const CallRecordsElectricityPrice = () => import('../views/callRecordsElectricityPrice/CallRecordsElectricityPrice.vue')
const CallRecordsMeasureMap = () => import('../views/callRecordsMeasureMap/CallRecordsMeasureMap.vue')
const CallRecordsQcc = () => import('../views/callRecordsQcc/CallRecordsQcc.vue')
const CallRecordsQccEcicomplement = () => import('../views/callRecordsQccEcicomplement/CallRecordsQccEcicomplement.vue')
const Circle = () => import('../views/circle/Circle.vue')
const CircleMember = () => import('../views/circleMember/CircleMember.vue')
const Company = () => import('../views/company/Company.vue')
const ComplaintAndSuggest = () => import('../views/complaintAndSuggest/ComplaintAndSuggest.vue')
const ElectricityPrice = () => import('../views/electricityPrice/ElectricityPrice.vue')
const ElectricityPriceTime = () => import('../views/electricityPriceTime/ElectricityPriceTime.vue')
const Emote = () => import('../views/emote/Emote.vue')
const EmoteCategory = () => import('../views/emoteCategory/EmoteCategory.vue')
const FinanceStationApply = () => import('../views/financeStationApply/FinanceStationApply.vue')
const InsureCompany = () => import('../views/insureCompany/InsureCompany.vue')
const Irradiation = () => import('../views/irradiation/Irradiation.vue')
const MeasureMap = () => import('../views/measureMap/MeasureMap.vue')
const MeasureMapDetail = () => import('../views/measureMapDetail/MeasureMapDetail.vue')
const MeasureSearchHistory = () => import('../views/measureSearchHistory/MeasureSearchHistory.vue')
const accessAnalysis = () => import('../views/accessAnalysis/accessAnalysis.vue')
const Member = () => import('../views/member/Member.vue')
const MemberCollect = () => import('../views/memberCollect/MemberCollect.vue')
const MemberFollow = () => import('../views/memberFollow/MemberFollow.vue')
const MemberForward = () => import('../views/memberForward/MemberForward.vue')
const MemberNotice = () => import('../views/memberNotice/MemberNotice.vue')
const MemberViewTime = () => import('../views/memberViewTime/MemberViewTime.vue')
const Merchant = () => import('../views/merchant/Merchant.vue')
const MerchantProduct = () => import('../views/merchantProduct/MerchantProduct.vue')
const MerchantProductSku = () => import('../views/merchantProductSku/MerchantProductSku.vue')
const Order = () => import('../views/order/Order.vue')
const OrderItem = () => import('../views/orderItem/OrderItem.vue')
const ProductCategory = () => import('../views/productCategory/ProductCategory.vue')
const QccCompanyselfriskcount = () => import('../views/qccCompanyselfriskcount/QccCompanyselfriskcount.vue')
const QccEcicomplement = () => import('../views/qccEcicomplement/QccEcicomplement.vue')
const QccEciinfooverview = () => import('../views/qccEciinfooverview/QccEciinfooverview.vue')
const QccFuzzySearch = () => import('../views/qccFuzzySearch/QccFuzzySearch.vue')
const QccJudgmentdoccheck = () => import('../views/qccJudgmentdoccheck/QccJudgmentdoccheck.vue')
const QccJudgmentdoccheckDetail = () => import('../views/qccJudgmentdoccheckDetail/QccJudgmentdoccheckDetail.vue')
const QccSearchHistory = () => import('../views/qccSearchHistory/QccSearchHistory.vue')
const Video = () => import('../views/video/Video.vue')
const VideoFairComment = () => import('../views/videoFairComment/VideoFairComment.vue')
const VideoLabel = () => import('../views/videoLabel/VideoLabel.vue')
const VideoProduct = () => import('../views/videoProduct/VideoProduct.vue')
const WindSpeed = () => import('../views/windSpeed/WindSpeed.vue')
const Fair = () => import('../views/fair/Fair.vue')
const FairLabel = () => import('../views/fairLabel/FairLabel.vue')
const  systemUpdateRecord=()=>import('../views/systemUpdateRecord/systemUpdateRecord.vue')


Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/index" },
  { path: "/login", component: Login },
  {
    path: "/index",
    component: Index,
    redirect: "/Welcome",
    children: [
      // { path: "/index", component: Welcome },
      { path: "/index", component: accessAnalysis },
      { path: "/employee", component: employeeManagement },
      { path: "/employee/details", component: employeeDetail },
      { path: "/organization", component: OrganizationManagement },
      { path: "/post", component: Post },
      { path: "/menu", component: Menu },
      { path: "/authority", component: Authority },
      { path: "/organizationType", component: OrganizationTypeMaintenance },
      { path: "/myAccountView", component: myAccountView },
      { path: "/roleMenu", component: RoleMenu },
      { path: "/systemConfig", component: SystemConfig },
      { path: "/systemConfig/details", component: SystemConfigDetails },
      { path: "/tableDesign", component: TableDesign },
      { path: '/coding', component: Table },
      { path:'/table/details',component:TableDetails },
      { path: '/tableDdl', component: TableDdl },
      { path:'/tableDdl/details',component:TableDdlDetails },
      { path: '/loginLog', component: LoginLog },
      { path:'/loginLog/details',component:LoginLogDetails },


      { path:'/fromDesigner',name: 'designer',component:FromDesigner },
      { path:'/dialog',  name: 'dialogTest',component:DialogTest },
      { path:'/queryDialog', name: 'queryDialogTest',component:QueryDialogTest },
      { path:'/view', name: 'view',component:View },
      { path:'/table',name: 'table',component:DesignTable },
      { path: '/area', component: Area },
      { path: '/callRecordsCalculate', component: CallRecordsCalculate },
      { path: '/callRecordsDownloadPolicy', component: CallRecordsDownloadPolicy },
      { path: '/callRecordsElectricityPrice', component: CallRecordsElectricityPrice },
      { path: '/callRecordsMeasureMap', component: CallRecordsMeasureMap },
      { path: '/callRecordsQcc', component: CallRecordsQcc },
      { path: '/callRecordsQccEcicomplement', component: CallRecordsQccEcicomplement },
      { path: '/circle', component: Circle },
      { path: '/circleMember', component: CircleMember },
      { path: '/company', component: Company },
      { path: '/complaintAndSuggest', component: ComplaintAndSuggest },
      { path: '/electricityPrice', component: ElectricityPrice },
      { path: '/electricityPriceTime', component: ElectricityPriceTime },
      { path: '/emote', component: Emote },
      { path: '/emoteCategory', component: EmoteCategory },
      { path: '/financeStationApply', component: FinanceStationApply },
      { path: '/insureCompany', component: InsureCompany },
      { path: '/irradiation', component: Irradiation },
      { path: '/measureMap', component: MeasureMap },
      { path: '/measureMapDetail', component: MeasureMapDetail },
      { path: '/measureSearchHistory', component: MeasureSearchHistory },
      { path: '/accessAnalysis', component:accessAnalysis },
      { path: '/member', component: Member },
      { path: '/memberCollect', component: MemberCollect },
      { path: '/memberFollow', component: MemberFollow },
      { path: '/memberForward', component: MemberForward },
      { path: '/memberNotice', component: MemberNotice },
      { path: '/memberViewTime', component: MemberViewTime },
      { path: '/merchant', component: Merchant },
      { path: '/merchantProduct', component: MerchantProduct },
      { path: '/merchantProductSku', component: MerchantProductSku },
      { path: '/order', component: Order },
      { path: '/orderItem', component: OrderItem },
      { path: '/productCategory', component: ProductCategory },
      { path: '/qccCompanyselfriskcount', component: QccCompanyselfriskcount },
      { path: '/qccEcicomplement', component: QccEcicomplement },
      { path: '/qccEciinfooverview', component: QccEciinfooverview },
      { path: '/qccFuzzySearch', component: QccFuzzySearch },
      { path: '/qccJudgmentdoccheck', component: QccJudgmentdoccheck },
      { path: '/qccJudgmentdoccheckDetail', component: QccJudgmentdoccheckDetail },
      { path: '/qccSearchHistory', component: QccSearchHistory },
      { path: '/video', component: Video },
      { path: '/videoFairComment', component: VideoFairComment },
      { path: '/videoLabel', component: VideoLabel },
      { path: '/videoProduct', component: VideoProduct },
      { path: '/windSpeed', component: WindSpeed },
      { path: '/fair', component: Fair },
      { path: '/fairLabel', component: FairLabel },
      {path:'/systemUpdateRecord',component:systemUpdateRecord},
      {
        "path":"/reviewvideo",
        component:()=>import("../views/videos/reviewVideo/reviewVideo.vue")
      },
      {
        "path":"/videolist",
        component:()=>import("../views/videos/videoList/videoList.vue")
      },
      {
        "path":"/advertise",
        component:()=>import("../views/advertise/advertise.vue")
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = ""; //token保存在localstorage中

    let item = localStorage.getItem("token");
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item;
    }
    if (item && item.startTime) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime;
      console.log("timelength:" + timel + ",expires:" + item.expires);
      if (timel < item.expires) {
        token = item.value;
      }
    }
    if (token === null || token === "") {
      // debugger
      next("/login");
    } else {

      next();
    }
  }
});

export default router;
