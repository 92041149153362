import { render, staticRenderFns } from "./dialogList.vue?vue&type=template&id=9d8eb348&scoped=true"
import script from "./dialogList.vue?vue&type=script&lang=js"
export * from "./dialogList.vue?vue&type=script&lang=js"
import style0 from "./dialogList.vue?vue&type=style&index=0&id=9d8eb348&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d8eb348",
  null
  
)

component.options.__file = "dialogList.vue"
export default component.exports