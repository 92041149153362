import { render, staticRenderFns } from "./fancyTable.vue?vue&type=template&id=56c64aed&scoped=true"
import script from "./fancyTable.vue?vue&type=script&lang=js"
export * from "./fancyTable.vue?vue&type=script&lang=js"
import style0 from "./fancyTable.vue?vue&type=style&index=0&id=56c64aed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c64aed",
  null
  
)

component.options.__file = "fancyTable.vue"
export default component.exports