import { render, staticRenderFns } from "./slider.vue?vue&type=template&id=684bddb0&scoped=true"
import script from "./slider.vue?vue&type=script&lang=js"
export * from "./slider.vue?vue&type=script&lang=js"
import style0 from "./slider.vue?vue&type=style&index=0&id=684bddb0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684bddb0",
  null
  
)

component.options.__file = "slider.vue"
export default component.exports